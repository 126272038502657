<template>
  <v-container class="py-7">
    <v-row dense>
      <v-col>
        <div class="heading mb-7">Ваши баллы</div>
      </v-col>
      <v-col cols="auto">
        <div class="heading primary--text">{{bonusesAvailable}}</div>
      </v-col>
    </v-row>
    <v-sheet
      class="custom-field"
      v-ripple="{ class: 'secondary--text' }"
      @click="$router.push({name: 'BonusesUp'})"
    >
      <span>История начисления</span>
      <v-icon color="secondary" size="32" class="mr-n2 ml-auto">{{icons.mdiChevronRight}}</v-icon>
    </v-sheet>
    <v-sheet
      class="custom-field"
      v-ripple="{ class: 'secondary--text' }"
      @click="$router.push({name: 'BonusesDown'})"
    >
      <span>История списания</span>
      <v-icon color="secondary" size="32" class="mr-n2 ml-auto">{{icons.mdiChevronRight}}</v-icon>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { mdiChevronRight } from "@mdi/js";

export default {
  name: "Bonuses",

  data: () => ({
    icons: {
      mdiChevronRight
    }
  }),

  computed: {
    bonusesAvailable() {
      return this.bonuses?.details?.total_available || 0;
    },

    ...mapState("user", ["bonuses"])
  },

  async created() {
    const { commit, dispatch } = this.$store;
    commit("overlay/update", { active: true });
    await dispatch("user/loadBonuses");
    commit("overlay/update", { active: false });
  }
};
</script>